<template>
  <div>
    <div class="section-title">
      <h3>Mostrando orden {{ order.no }}</h3>
      <ellipsis-drop-down title="Acciones">
        <ellipsis-drop-down-item title="Cancelar"
                                 body="Cancelar el elemento actual"
                                 v-if="order.can_cancel"
                                 icon="fa fa-trash">
        </ellipsis-drop-down-item>
        <ellipsis-drop-down-item title="Facturar"
                                 v-if="order.can_be_billed_particular && ['LAPI', 'KIDS'].includes(this.$auth.user().data_branch_office.group) && ($alfalab.enableByKey($auth.user(), {enableKey: 'cash_close_service_enable'}))"
                                 body="Generar la factura para la orden correspondiente"
                                 :to="{name:'order-billed', params: {id: order.order_new}}"
                                 icon="fa fa-file-pdf">
        </ellipsis-drop-down-item>
        <template v-if="false">
        <ellipsis-drop-down-item title="Nota de Crédito"
                                 v-if="order.can_make_credit_note &&  ['LAPI', 'KIDS'].includes(this.$auth.user().data_branch_office.group) && ($alfalab.enableByKey($auth.user(), {enableKey: 'cash_close_service_enable'}))"
                                 body="Generar la nota de crédito para la orden correspondiente"
                                 event="click"
                                 @click="makeCreditNote"
                                 icon="fa fa-file-pdf">
        </ellipsis-drop-down-item>
        </template>
      </ellipsis-drop-down>
    </div>
    <div class="box">
      <div class="content">
        <div class="is-divider" data-content="Detalle de la orden."></div>
        <p><strong>Fecha:</strong> {{ order.date | date('LL') }}</p>
        <p v-if="order.branch_data"><strong>Sucursal:</strong> {{ order.branch_data.name }}</p>
        <p v-if="order.has_company"><strong>Empresa:</strong> {{ order.company.name }}</p>
        <order-data-patient v-if="order.patient"
                            :patient="order.patient">
        </order-data-patient>
        <div class="is-divider" data-content="Estudios"></div>
        <order-data-studies :studies="order.studies"
                            :show-price="showPrice">
        </order-data-studies>
        <template v-if="billed">
          <div class="is-divider" data-content="Datos Fiscales"></div>
          <form-patient-tax-data
              :data="taxData"
              :errors="errorsTaxData"
              @setTaxData="setTaxData"
              :edit="hasTaxData"></form-patient-tax-data>
        </template>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import OrderDataPatient from "./OrderDataPatient";
import OrderDataStudies from "./OrderDataStudies";
import EllipsisDropDown from "../utils/EllipsisDropDown";
import EllipsisDropDownItem from "../utils/EllipsisDropDownItem";
import FormPatientTaxData from "@/components/patient/FormPatientTaxData";
export default {
  name: "OrderData",
  components: {FormPatientTaxData, EllipsisDropDownItem, EllipsisDropDown, OrderDataStudies, OrderDataPatient},
  props: {
    order: Object,
    taxData: Object,
    errorsTaxData: Object,
    billed: Boolean,
    hasTaxData: Boolean
  },
  data()  {
    return {
      in_process: false
    }
  },
  computed: {
    company() {
      return this.order.company || {}
    },
    showPrice() {
      return this.company.general_public || ! this.order.has_company;
    },
    patient() {
      return this.order.patient || {}
    }
  },
  methods: {
    setTaxData(val) {
      for (let item in val) {
        this.taxData[item] = val[item];
      }
    },
    makeCreditNote(){
      this.$buefy.dialog.confirm({
        title: 'Nota de Creédito',
        message: '¿Estas seguro que deseas generar la nota de crédito de está orden?',
        confirmText: 'Si, generar',
        cancelText: 'No, cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          if(!this.in_process){
            this.in_process = true;
            this.$loader.show();
            this.$http.post('order-credit-note', {
              order: this.order.order_new
            }).then(({data}) => {
              this.$toast.success(data.message);
            }).catch(error => {
              this.$alfalab.showError(error);
            }) .finally(() => {
              this.$loader.hide();
              this.$emit('refresh-order');
              this.in_process = false;
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
